<template>
  <div class="container" :style="{ 'backgroundImage': 'url(' + info.share_video_cover_url + ')' }">
    <div class="main">
      <span class="char" char="第">第</span>
      <span class="char" :char="rank || '?'">{{ rank || '?' }}</span>
      <span class="char" char="位">位</span>
    </div>
  </div> 
</template>

<script>
import $fetch from "@/utils/fetch.js";
export default {
  data() {
    return {
      checkInLocationId: "",
      auth: "",
      info: {},
      rank: "",
    }
  },
  created() {
    const { checkInLocationId, auth } = this.$route.query;
    this.checkInLocationId = checkInLocationId;
    this.auth = auth;
  },
  mounted() {
    this.handleGetConfig();
    this.handleGetCheckInRank();
  },
  methods: {
    /** 获取景点配置 */
    async handleGetConfig() {
      const { checkInLocationId, auth: Authorization } = this;
      $fetch
        .post("GetCheckLocationInfo", { checkInLocationId }, { Authorization: `sid-${ Authorization }` })
        .then((res) => {
          this.info = res;
        });
    },
    /** 获取打卡名次 */
    async handleGetCheckInRank() {
      const { checkInLocationId, auth: Authorization } = this;
      $fetch
        .post("GetCheckInRank", { checkInLocationId, type: 1 }, { Authorization: `sid-${ Authorization }` })
        .then((res) => {
          this.rank = res.rank;
        });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1;

.container {
  position: relative;
  width: 100vw;
  height: 216.53vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .main {
    position: absolute;
    left: 50%;
    top: px2rem(356 * $multipleCount);
    transform: translateX(-50%);

    .char {
      position: relative;
      color: #30f293;
      font-size: px2rem(52 * $multipleCount);
      line-height: px2rem(64 * $multipleCount);

      &::before {
        content: attr(char);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        text-shadow:
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000,
          0 0 px2rem(12 * $multipleCount) #000;
        z-index: -1;
      }

      &:nth-child(2) {
        color: #ffe60f;
        margin: 0 px2rem(12 * $multipleCount);
      }
    }
  }
}
</style>
